import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"

const GridC = () => {
  const data = useStaticQuery(graphql`
    {
      small: allMasonTestimonialCreative(
        filter: { meta: { name: { eq: "sec3_small" } } }
        sort: { fields: created_time, order: ASC }
      ) {
        nodes {
          url
          meta {
            name
            testimonial
          }
        }
      }
      medium: allMasonTestimonialCreative(
        filter: { meta: { name: { eq: "sec3_med" } } }
        sort: { fields: created_time, order: ASC }
      ) {
        nodes {
          url
          meta {
            name
            testimonial
          }
        }
      }
    }
  `)
  return (
    <div className="grid-layout-wrap grid-c-wrap grid grid-cols-4 grid-rows-2 gap-4 my-4">
      {data.small.nodes.map(card => (
        <div className="grid-img-wrap small-grid-card">
          <a
            target="_blank"
            href={card.meta.testimonial !== null ? card.meta.testimonial : "/"}
          >
            <img
              className="w-full h-full object-cover"
              src={card.url}
              alt="grid image"
            />
          </a>
        </div>
      ))}
      {data.medium.nodes.map(card => (
        <div className="grid-img-wrap medium-grid-card">
          <a
            target="_blank"
            href={card.meta.testimonial !== null ? card.meta.testimonial : "/"}
          >
            <img
              className="w-full h-full object-cover"
              src={card.url}
              alt="grid image"
            />
          </a>
        </div>
      ))}
    </div>
  )
}

export default GridC
