import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"

const GridB = () => {
  const data = useStaticQuery(graphql`
    {
      allMasonTestimonialCreative(
        filter: { meta: { name: { eq: "watch" } } }
        sort: { fields: created_time, order: ASC }
      ) {
        nodes {
          url
          meta {
            name
            testimonial
          }
        }
      }
    }
  `)
  return (
    <div className="grid-layout-wrap grid-b-wrap grid grid-cols-4 grid-rows-2 gap-4 my-4">
      {data.allMasonTestimonialCreative.nodes.map((card, i) => (
        <div
          className={`grid-img-wrap ${
            i !== 4 ? "small-grid-card" : "big-grid-card"
          }`}
        >
          <a
            target="_blank"
            to={card.meta.testimonial !== null ? card.meta.testimonial : "/"}
          >
            <img
              className="w-full h-full object-cover"
              src={card.url}
              alt="banner image"
            />
          </a>
        </div>
      ))}
    </div>
  )
}

export default GridB
