import { Link } from "gatsby"
import React, { useEffect, useState } from "react"
import { ChevronLeft, ChevronRight } from "react-feather"
import "./Slideshow.scss"

const Slideshow = ({ data }) => {
  const [index, setIndex] = useState(0)
  useEffect(() => {
    const autoplay = setTimeout(() => {
      index === data.length - 1 ? setIndex(0) : setIndex(index + 1)
    }, 2000)
    return () => clearTimeout(autoplay)
  }, [index])
  return (
    <div className="slider-wrap w-full h-full relative">
      <div className="controls">
        <div
          className="btn shadow-md prev"
          onClick={() => {
            index === 0 ? setIndex(data.length - 1) : setIndex(index - 1)
          }}
        >
          <ChevronLeft />
        </div>
        <div
          className="btn shadow-md next"
          onClick={() => {
            index === data.length - 1 ? setIndex(0) : setIndex(index + 1)
          }}
        >
          <ChevronRight />
        </div>
      </div>
      {data.map((card, i) => (
        <div
          className={`slider-card-wrap ${
            index === i ? "slider-card-active" : "hidden"
          }`}
        >
          <Link to={card.testimonial ? card.testimonial : "/"}>
            <img
              className="w-full h-full object-cover"
              src={card.url}
              alt="slide image"
            />
          </Link>
        </div>
      ))}
    </div>
  )
}

export default Slideshow
