import React from "react"
import Slideshow from "../SlideShow/Slideshow"
import { useStaticQuery, graphql, Link } from "gatsby"

const GridE = () => {
  const data = useStaticQuery(graphql`
    {
      allMasonTestimonialCreative(
        filter: { meta: { name: { eq: "product" } } }
      ) {
        nodes {
          url
          meta {
            name
            testimonial
          }
        }
      }
    }
  `)
  return (
    <div className="grid-layout-wrap grid-e-wrap grid grid-cols-4 grid-rows-2 gap-4 my-4">
      {data.allMasonTestimonialCreative.nodes.map(card => (
        <div className="grid-img-wrap small-grid-card">
          <a target="_blank" to={card.meta.testimonial!==null ? card.meta.testimonial : "/"}>
            <img
              className="w-full h-full object-cover"
              src={card.url}
              alt="product image"
            />
          </a>
        </div>
      ))}
    </div>
  )
}

export default GridE
