import React from "react"
import Slideshow from "../SlideShow/Slideshow"
import { useStaticQuery, graphql, Link } from "gatsby"

const GridA = () => {
  const data = useStaticQuery(graphql`
    {
      slides: allMasonTestimonialCreative(
        filter: { meta: { name: { eq: "slide" } } }
      ) {
        nodes {
          url
          meta {
            name
            testimonial
          }
        }
      }
      products: allMasonTestimonialCreative(
        filter: { meta: { name: { eq: "shoe" } } }
      ) {
        nodes {
          url
          meta {
            name
            testimonial
          }
        }
      }
    }
  `)
  // const products = useStaticQuery(graphql`
  //   {

  //   }
  // `)
  return (
    <div className="grid-layout-wrap grid-a-wrap grid grid-cols-4 grid-rows-2 gap-4 my-4">
      {data.products.nodes.map(card => (
        <div className="grid-img-wrap small-grid-card">
          <a target="_blank" href={card.meta.testimonial!==null ? card.meta.testimonial : "/"}>
            <img
              className="w-full h-full object-cover"
              src={card.url}
              alt="product image"
            />
          </a>
        </div>
      ))}
      <div className="relative grid-img-wrap big-grid-card">
        <Slideshow data={data.slides.nodes} />
      </div>
    </div>
  )
}

export default GridA
