import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"

const SmallBanner = () => {
  const data = useStaticQuery(graphql`
    {
      allMasonTestimonialCreative(
        filter: { meta: { name: { eq: "small_banner" } } }
        sort: { fields: created_time, order: ASC }
      ) {
        nodes {
          url
          meta {
            name
            testimonial
          }
        }
      }
    }
  `)
  return (
    <div className="banner-small-wrap my-4 h-auto md:h-96 rounded-none md:rounded-md overflow-hidden">
      {data.allMasonTestimonialCreative.nodes.map(card => (
        <div className="banner-img-wrap">
          <a href={card.meta.testimonial !==null ? card.meta.testimonial : "/"}>
            <img
              className="w-full h-full object-cover"
              src={card.url}
              alt="grid image"
            />
          </a>
        </div>
      ))}
    </div>
  )
}

export default SmallBanner
